/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-06-02",
    versionChannel: "nightly",
    buildDate: "2024-06-02T00:06:09.293Z",
    commitHash: "e3316aac52b3c6c4b64e0dcdab779f77c2c6f362",
};
